import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
axios.defaults.baseURL = '//zzdxapi.hzfc.5n5.cn/zzdx'// https已拦截跨源请求：同源策略禁止读取位于
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
Vue.prototype.axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.directive('title', {
  inserted: function (el, binding) {
    if (el.dataset.title) {
      document.title = el.dataset.title
    } else {
      document.title = 'loading...'
    }
  }
})
new Vue({
  router: router,
  store: store,
  render: h => h(App)
}).$mount('#app')
